// Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const logo='viztech.png'

  return (
    <header className="header">
      <Link to="/"  className="brand">
      <img src={`${process.env.PUBLIC_URL}/Photos/${logo}` } alt="Logo" style={{ height: '70px', marginRight: '70px' }}/></Link>
      <button className="hamburger" onClick={toggleMenu}>
        ☰ {/* Hamburger icon */}
      </button>
      <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <Link to="/" className="nav-link" onClick={() => setMenuOpen(false)}>Home</Link>
        <Link to="/aboutus" className="nav-link" onClick={() => setMenuOpen(false)}>About Us</Link>
        {/* <Link to="/MultiStepForm" className="nav-link" onClick={() => setMenuOpen(false)}>Multi-Step Form</Link>
        <Link to="/records" className="nav-link" onClick={() => setMenuOpen(false)}>Records</Link> */}
        <Link to="/contactus" className="nav-link" onClick={() => setMenuOpen(false)}>Contact Us</Link>
        <Link to="/login" className="nav-link" onClick={() => setMenuOpen(false)}>Login</Link>
        <Link to="/register" className="nav-link" onClick={() => setMenuOpen(false)}>Register</Link>
        <Link to="/services" className="nav-link" onClick={() => setMenuOpen(false)}>Services</Link>
        <Link to="/findus" className="nav-link" onClick={() => setMenuOpen(false)}>Findus</Link>
      </nav>
      <div className="contact-info">
                <FontAwesomeIcon icon={faPhone} className="phone-icon" />
                <a href="tel:9492512246" className="phone-number" >9492512246</a>                   
            </div>
            <div className="contact-info">
                <FontAwesomeIcon icon={faPhone} className="phone-icon" />
            <a href="tel:9986203191" className="phone-number">9986203191</a>
</div>
    </header>
  );
};

export default Header;
