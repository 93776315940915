// Services.js
import React from 'react';

const Services = () => {
    return (

        <div>
                    <br></br>
                    <br></br>
            <h1>Our Services</h1>
            <p>We offer a variety of services to help you succeed:</p>
            <ul>
                <li>Web Development</li>
                <li>Mobile Application Development</li>
                <li>Cloud Solutions</li>
                <li>Consulting Services</li>
                <li>UI/UX Design</li>
            </ul>
            <div>
                	
		WEB DESIGN & DEVELOPMENT<br></br>
VizTech offers customer-oriented web design and development services and more importantly, deliver them effectively.

 

             </div>
        </div>
        
    );
};

export default Services;
