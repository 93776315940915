import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import BackButton from './BackButton';
const RecordsTable = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch records from the API
  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await fetch('http://localhost:5000/records'); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setRecords(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRecords();
  }, []);

  // Define columns for the data table
  const columns = [
    { name: 'ID', selector: (row) => row.id, sortable: true },
    { name: 'Field 1', selector: (row) => row.field1, sortable: true },
    { name: 'Field 2', selector: (row) => row.field2, sortable: true },
    { name: 'Field 3', selector: (row) => row.field3, sortable: true },
  ];

  // Handle loading and error states
  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error}</div>;
  }

  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">Records Table</h1>
      <DataTable
        title=""
        columns={columns}
        data={records}
        pagination
        progressPending={loading}
        fixedHeader
        highlightOnHover
        className="table-striped table-bordered" // Bootstrap classes for styling
      /><BackButton />
    </div>
  );
};

export default RecordsTable;
