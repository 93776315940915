// WhatsNew.js
import React, { useEffect, useState } from 'react';
import './WhatsNew.css';

const WhatsNew = () => {
    const [messages, setMessages] = useState([]);
    const [textColor, setTextColor] = useState('#007bff'); // Default color

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await fetch('https://apiviztech.in:5005/messages');
                const data = await response.json();
                setMessages(data);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();

        // Function to change the text color randomly every 5 seconds
        const changeColor = () => {
            const randomColor = '#' + Math.floor(Math.random()*16777215).toString(16); // Generate random hex color
            setTextColor(randomColor);
        };

        const colorInterval = setInterval(changeColor, 5000); // Change color every 5 seconds

        return () => clearInterval(colorInterval); // Cleanup on unmount
    }, []);

    return (
        <div className="whats-new-container">
            <h3 className="whats-new-title">What's New</h3>
            <div className="scrolling-text">
                {messages.map((item, index) => (
                    <div key={index} className="message" style={{ color: textColor }}>
                        {item.message}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WhatsNew;
