// AboutUs.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutUs = () => {
  return (
    <div>
      <br></br>
      <br></br>
      <h2>About Us</h2>
      <p>The general purpose of VizTech is to develop and promote advanced information technologies for multi-user operation.

      VizTech's business philosophy is to assure the highest quality product, total client satisfaction, timely delivery of solutions and the best quality/price ratio found in the industry.

Our emphasis is on offering a high degree of product user friendliness through a positive, creative and focused company staff.</p>
    </div>
  );
};

export default AboutUs;
