import React, { useState } from 'react';
import styled from 'styled-components';
import { FaComments } from 'react-icons/fa';
import jsPDF from 'jspdf';

// Styled Components
const ChatContainer = styled.div`
  width: 300px;
  height: 500px;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 70px;
  right: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: ${(props) => (props.isVisible ? 'auto' : 'none')};
  z-index: 900;
  background-color: #fff;
`;

const ChatHeader = styled.div`
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
`;

const MessagesContainer = styled.div`
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f8f9fa;
`;

const Message = styled.div`
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  background-color: ${(props) => (props.isUser ? '#007bff' : '#e9ecef')};
  color: ${(props) => (props.isUser ? 'white' : 'black')};
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
`;

const InputContainer = styled.div`
  display: flex;
  padding: 10px;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SendButton = styled.button`
  padding: 10px;
  margin-left: 5px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ActionButton = styled.button`
  margin-top: 10px;
  padding: 10px;
  border: none;
  background-color: #28a745;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const ClearButton = styled.button`
  margin-top: 10px;
  padding: 10px;
  border: none;
  background-color: #dc3545;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #c82333;
  }
`;

const ToggleButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  color: white;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 1000;

  &:hover {
    background-color: #0056b3;
  }
`;

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [studentId, setStudentId] = useState('');
  const [waitingForId, setWaitingForId] = useState(false); // State for waiting for student ID

  const handleSendMessage = () => {
    if (inputValue.trim() === '') return;

    const newMessages = [...messages, { text: inputValue, isUser: true }];
    setMessages(newMessages);
    
    if (waitingForId) {
      // If waiting for ID, fetch marks when the user sends a message
      setStudentId(inputValue);
      fetchMarks(inputValue);
      setWaitingForId(false); // Reset waiting state
    } else {
      // Otherwise, process the message normally
      const botResponse = getBotResponse(inputValue);
      setMessages((prevMessages) => [...prevMessages, { text: botResponse, isUser: false }]);
    }

    setInputValue(''); // Clear the input field
  };

  const getBotResponse = (userMessage) => {
    const normalizedMessage = userMessage.toLowerCase();
    if (normalizedMessage.includes('marks')) {
      setWaitingForId(true); // Set waiting for ID state
      return 'Please enter your student ID to retrieve marks.';
    }
    return 'Sorry, I did not understand that.';
  };

  const fetchMarks = (id) => {
    fetch(`https://apiviztech.in:5005/marks/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: `Marks retrieved for ID ${id}: ${JSON.stringify(data)}`, isUser: false },
        ]);
        generatePDF(data);
      })
      .catch((error) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: 'Error retrieving marks, please try again.', isUser: false },
        ]);
      });
  };

  const generatePDF = (marksData) => {
    const doc = new jsPDF();
    doc.text('Student Marks Report', 10, 10);
    doc.text(`Student ID: ${studentId}`, 10, 20);
    Object.keys(marksData).forEach((subject, index) => {
      doc.text(`${subject}: ${marksData[subject]}`, 10, 30 + index * 10);
    });
    doc.save(`Student_${studentId}_Marks.pdf`);
  };

  const handleMarksRequest = () => {
    setWaitingForId(true); // Set waiting for ID state
    setMessages((prevMessages) => [...prevMessages, { text: 'Please enter your Student ID:', isUser: false }]);
  };

  const handleServicesRequest = () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: 'Here are our services: Web Development, Mobile App Development, UI/UX Design, Digital Marketing.', isUser: false }
    ]);
  };

  const handleClearChat = () => {
    setMessages([]); // Clear the chat history
  };

  const toggleChatbot = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <ToggleButton onClick={toggleChatbot}>
        <FaComments size={20} />
      </ToggleButton>
      <ChatContainer isVisible={isVisible}>
        <ChatHeader>
          <span>Chatbot</span>
          <CloseButton onClick={toggleChatbot}>✖</CloseButton>
        </ChatHeader>
        <MessagesContainer>
          {messages.map((message, index) => (
            <Message key={index} isUser={message.isUser}>
              {message.text}
            </Message>
          ))}
          <Message isUser={false}>
            {/* <ActionButton onClick={handleMarksRequest}>Marks</ActionButton> */}
            <ActionButton onClick={handleServicesRequest}>Services</ActionButton>
            <ClearButton onClick={handleClearChat}>Clear Chat</ClearButton>
          </Message>
        </MessagesContainer>
        <InputContainer>
          <Input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
            placeholder="Type your message..."
          />
          <SendButton onClick={handleSendMessage}>Send</SendButton>
        </InputContainer>
      </ChatContainer>
    </>
  );
};

export default Chatbot;
