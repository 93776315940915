import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <button onClick={goBack} style={{ margin: '10px', padding: '10px' }}>
      Go Back
    </button>
  );
};

export default BackButton;
