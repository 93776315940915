// Services.js
import React from 'react';

const FindUs = () => {
    return (

        <div>
                    <br></br>
            <h1>Location</h1>
            <p><i>Bidar</i></p>
             
        </div>
        
    );
};

export default FindUs;
