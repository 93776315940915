import React from 'react';
import Carosel from './Carosel';
import WhatsNew from './WhatsNew';
import './Home.css';

const Home = () => {
    return (
        <div style={{ maxWidth: '1200px', margin: 'auto', padding: '20px', display: 'flex', gap: '20px' }}>
        <br></br>
 
            <div style={{ flex: 1 }}>
            <br></br>
             
 
                <Carosel />
            </div>
            <br></br>
            <br></br>
 
            {/* <div style={{ flex: 3 }}>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
 
                <WhatsNew />       <br></br>
 
            </div> */}
            
        </div>
    );
};

export default Home;
