import React, { useState } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MultiStepForm from './MultiStepForm';
import AboutUs from './AboutUs'; 
import Login from './Login'; 
import RecordsTable from './RecordsTable';
import Header from './Header';
import ContactUs from './ContactUs';
import Register from './Register';
import Services from './Services';
import Footer from './Footer';
import './App.css';
import FindUs from './FindUs';
import WhatsNew from './WhatsNew';
import Chatbot from './Chatbot';
import Home from './Home';
import FlashMessage from './FlashMessage';

const App = () => {
    const [isWhatsNewVisible, setIsWhatsNewVisible] = useState(true);
    const [showFlashMessage, setShowFlashMessage] = useState(true);

    const handleCloseWhatsNew = () => {
        setIsWhatsNewVisible(false);
    };

    const handleCloseFlashMessage = () => {
        setShowFlashMessage(false);
    };

    return (
        <Router>
            <div className="app-background"></div>

            {/* Render FlashMessage conditionally */}
            {showFlashMessage && (
                <FlashMessage
                    message="Welcome to our website! Check out the latest updates."
                    onClose={handleCloseFlashMessage}
                />
            )}

            <Chatbot />
            <Header />
            <div className="container">
                {isWhatsNewVisible && <WhatsNew onClose={handleCloseWhatsNew} />}
                
                <TransitionGroup>
                    <Routes>
                        <Route 
                            path="/" 
                            element={
                                <CSSTransition classNames="fade" timeout={300}>
                                    <Home />
                                </CSSTransition>
                            } 
                        />
                        <Route 
                            path="/aboutus" 
                            element={
                                <CSSTransition classNames="fade" timeout={300}>
                                    <AboutUs />
                                </CSSTransition>
                            } 
                        />
                        <Route 
                            path="/services" 
                            element={
                                <CSSTransition classNames="fade" timeout={300}>
                                    <Services />
                                </CSSTransition>
                            } 
                        />
                        <Route 
                            path="/login" 
                            element={
                                <CSSTransition classNames="fade" timeout={300}>
                                    <Login />
                                </CSSTransition>
                            } 
                        />
                        <Route 
                            path="/contactus" 
                            element={
                                <CSSTransition classNames="fade" timeout={300}>
                                    <ContactUs />
                                </CSSTransition>
                            } 
                        />
                        <Route 
                            path="/register" 
                            element={
                                <CSSTransition classNames="fade" timeout={300}>
                                    <Register />
                                </CSSTransition>
                            } 
                        />
                        <Route 
                            path="/findus" 
                            element={
                                <CSSTransition classNames="fade" timeout={300}>
                                    <FindUs />
                                </CSSTransition>
                            } 
                        />
                        <Route 
                            path="/whats-new" 
                            element={
                                <CSSTransition classNames="fade" timeout={300}>
                                    <WhatsNew />
                                </CSSTransition>
                            } 
                        />
                    </Routes>
                </TransitionGroup>
            </div>
            <Footer />
        </Router>
    );
};

export default App;
