import React from 'react';
import './FlashMessage.css';

const FlashMessage = ({ message, onClose }) => {
  return (
    <div className="flash-message">
      <span>{message}</span>
      <button onClick={onClose} className="close-button">×</button>
    </div>
  );
};

export default FlashMessage;
