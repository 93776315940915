// Home.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Home.css';
const Carosel = () => {
    // Array of image file names
    const images = [
        'image1.jpg',
        'image2.jpg',
        'image3.jpg',
        'image4.jpg'
    ];

    return (
        
        <div style={{ maxWidth: '700px', margin: 'auto', padding: '20px' }}>
            {/* <h2>Welcome to VizTech</h2> */}
        
            <Carousel showThumbs={false} autoPlay infiniteLoop>
                {images.map((image, index) => (
                    <div key={index}>
                        <img src={`${process.env.PUBLIC_URL}/Photos/${image}`} alt={`Slide ${index + 1}`} />
                        <p className="legend">{`Slide ${index + 1}`}</p>
                    </div>
                ))}
            </Carousel>
            
        </div>
        
    );
};

export default Carosel;
